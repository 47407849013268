import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Loading from "../components/Loading";
import BanStatus from "../components/BanStatus";
import BanAppealForm from "../components/BanAppealForm";
import axios from "axios";
import NotFound from "./NotFound";

function BanAppeal() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  useEffect(() => {
    axios.get(`/banappeal/${token}`).then((res) => {
      setStatus(res.data.message);
      setLoading(false);
    });
  }, [token]);

  if (loading) return <Loading />;
  if (status === "invalid token") return <NotFound />;
  if (status === "not submitted") return <BanAppealForm token={token}/>;
  return <BanStatus status={status}/>
}

export default BanAppeal;
