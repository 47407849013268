import axios from "axios";
import { useState } from "react";

function BanAppealForm({ token }) {
  const [state, setState] = useState({
    message: "",
  });
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }

  function submit(message, token) {
    axios.post(`/banappeal/${token}`, { message }).then((res) => {
      setProcessing(false);
      if (res.data.message === "submitted") {
        setSubmitted(true);
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setProcessing(true);
    submit(state.message, token);
  };

  if (submitted) {
    return (
      <div className="min-h-screen pt-16 pb-12 flex flex-col bg-slate-900">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <div className="h-20 w-20 flex items-center justify-center rounded-full">
              <img
                src="/images/keqing_icon_rounded.png"
                alt="Icon"
                height="75"
                width="75"
              />
            </div>
          </div>
          <div className="py-16">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
                Your application has been submitted!
              </h1>
              <p className="mt-2 text-base text-gray-400">
                Your application has been submitted and is pending review. visit
                this page after 48h to see if it has been approved.
              </p>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-slate-900 py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <div className="flex-shrink-0 flex justify-center">
            <div className="h-20 w-20 flex items-center justify-center rounded-full mb-8">
              <img
                src="/images/keqing_icon_rounded.png"
                alt="Icon"
                height="75"
                width="75"
              />
            </div>
          </div>
          <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Ban Appeal Form
          </h2>
          <p className="text-gray-500 mt-4">
            please note that filling this form will not guarantee your unban,
            the final decision is ours to take.
          </p>
        </div>
        <div className="mt-12">
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-slate-400"
              >
                Message
              </label>
              <div className="mt-1">
                <textarea
                  onChange={handleChange}
                  required
                  id="message"
                  name="message"
                  minLength={30}
                  rows={4}
                  className="bg-slate-800 text-white Myshadow py-3 px-4 block w-full focus:ring-0 border-none hover:bg-slate-700 focus:bg-slate-700 focus:outline-none rounded-md"
                  defaultValue={""}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <button
                type="submit"
                className="Myshadow w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
              >
                {processing ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BanAppealForm;
