import Error from "../pages/Error";

function BanStatus({ status }) {
  switch (status) {
    case "pending":
      return (
        <div className="min-h-screen pt-16 pb-12 flex flex-col bg-slate-900">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex justify-center">
              <div className="h-20 w-20 flex items-center justify-center rounded-full">
                <img
                  src="/images/keqing_icon_rounded.png"
                  alt="Icon"
                  height="75"
                  width="75"
                />
              </div>
            </div>
            <div className="py-16">
              <div className="text-center">
                <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
                  Your application is pending review
                </h1>
                <p className="mt-2 text-base text-gray-400">
                  it takes less than 48h in most cases, but sometimes it might take longer.
                </p>
              </div>
            </div>
          </main>
        </div>
      );
    case "approved":
      return(
        <div className="min-h-screen pt-16 pb-12 flex flex-col bg-slate-900">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex justify-center">
              <div className="h-20 w-20 flex items-center justify-center rounded-full">
                <img
                  src="/images/keqing_icon_rounded.png"
                  alt="Icon"
                  height="75"
                  width="75"
                />
              </div>
            </div>
            <div className="py-16">
              <div className="text-center">
                <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
                  Your application has been approved!
                </h1>
                <p className="mt-2 text-base text-gray-400">
                  here is a <a href="https://ayaka.link/gidz" className="text-purple-500 underline hover:text-purple-700">link</a> to the server if you need one
                </p>
              </div>
            </div>
          </main>
        </div>
      )
    case "denied":
      return(
        <div className="min-h-screen pt-16 pb-12 flex flex-col bg-slate-900">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex justify-center">
              <div className="h-20 w-20 flex items-center justify-center rounded-full">
                <img
                  src="/images/keqing_icon_rounded.png"
                  alt="Icon"
                  height="75"
                  width="75"
                />
              </div>
            </div>
            <div className="py-16">
              <div className="text-center">
                <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
                  Your application has been denied
                </h1>
                <p className="mt-2 text-base text-gray-400">
                  well... unfortunately, your application has been denied, but don't let this get you down, it's hard to get up again.
                </p>
              </div>
            </div>
          </main>
        </div>
      )
    default:
      return <Error />;
  }
}

export default BanStatus;
