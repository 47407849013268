import { Link } from "react-router-dom";

function NotFound() {
  return(
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-slate-900">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <div className="h-20 w-20 flex items-center justify-center rounded-full">
            <img
              src="/images/keqing_icon_rounded.png"
              alt="Icon"
              height="75"
              width="75"
            />
          </div>
        </div>
        <div className="py-16">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
              Not Found
            </h1>
            <p className="mt-2 text-base text-gray-400">
              You look lost, stranger.
            </p>
            <div className="mt-6">
              <Link to="/">
                <button className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default NotFound;
