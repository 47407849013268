function Loading() {
  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-slate-900">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <div className="inline-flex">
            <div className="h-12 w-auto">
              <img
                src="/images/keqing_icon_rounded.png"
                alt=""
                height="100"
                width="100"
              />
            </div>
          </div>
        </div>
        <div className="py-24">
          <div className="w-full inline-flex items-center justify-center px-6 py-3">
            <svg
              className="animate-spin h-12 w-12 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Loading;
