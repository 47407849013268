import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import "./index.css";
import App from "./App";

axios.defaults.baseURL = "https://keqing-dev-api.loca.lt";
// axios default Header
axios.defaults.headers.common["Bypass-Tunnel-Reminder"] =
  "U GAY WHY SEEING THIS";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
