function Home() {
  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-slate-900">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <div className="inline-flex">
            <div className="h-12 w-auto">
              <img
                src="/images/keqing_icon_rounded.png"
                alt=""
                height="100"
                width="100"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
